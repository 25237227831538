@import "../../styles/main.scss";

#topbar {
    user-select: none;
    z-index: 99;
    width: 100%;
    height: 10vh;
    background-color: $hex-topbar-white;

    display: flex;
    justify-content: space-between;
    position: fixed;

    @include breakTarget(ipad-pro, (max-height: height, max-width: width), landscape) {
        height: 15vh;
    }
    @include breakTarget(iphone6-8-plus, (max-height: height, max-width: width), portrait) {
        height: 8vh;
    }

    .left-container {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakTarget(iphone6-8-plus, (max-height: height, max-width: width), both) {
            width: 70px;
        }
    }

    .center-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            display: inline-block;
            color: #1f2831;
            font-family: 'Roboto';
            font-size: 120%;
            letter-spacing: 15px;
            text-transform: uppercase;
            font-weight: 400;

            @include breakTarget(iphone8-plus, (max-height: height, max-width: width), portrait) {
                display: none;
            }
        }

        #JLogo {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            height: 70%;
            margin: 0 5px;

            #Top, #Bottom {
                fill: black;
            }
            #Hero {
                fill: $hex-hero-blue;
            }
        }
    }

    .right-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2%;

        .icon-container {
            display: flex;
            text-align: center;
            vertical-align: middle;
            height: 41px;
            z-index: 999;

            a {
                display: block;
                margin: 2px;

                svg path {
                    fill: inherit
                }
            }
        }
    }
}