@import "../../styles/main.scss";

#landing-splash {
    background-color: $hex-hero-dark;
    height: 90vh;
    padding-top: 10vh;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    h1 {
        color: #F1F2EF;
        font-family: 'Source Sans Pro';
        font-size: 500%;
        letter-spacing: 15px;
        text-transform: uppercase;

        .blink {
            animation: blink-animation 2s steps(2, start) infinite;
            -webkit-animation: blink-animation 2s steps(2, start) infinite;
        }
    }

    @include breakTarget(iphone6-8-plus, (max-height: height, max-width: width), portrait) {
        h1 {
            margin-top: 0;
            font-size: 220%;
            letter-spacing: 10px;
        }
    }
}