
.timeline-wrapper {
    min-height: 90vh;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 10vh;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background: white;
        left: calc(50% - 1px);
    }

    #start-point {
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        left: calc(50% - 15px);
        border-radius: 50%;
        background: white;
    }

    .event-wrapper {

        margin: 50px 0;

        .point {
            width: 14px;
            height: 14px;
            display: block;
            position: absolute;
            left: calc(50% - 7px);
            border-radius: 50%;
            background: white;
        }

        .content-wrapper {
            display: inline-block;
            max-width: 35%;
            top: -5px;
            user-select: none;
            color: white;

            h1, h2, h3, h4 {
                margin: 0;
            }

            &[event-position="left"] {
                transform: translateX(-50%);
                margin-right: 150px;
                text-align: right;
            }
            &[event-position="right"] {
                transform: translateX(50%);
                margin-left: 150px;
                text-align: left;
            }
        }
    }

    > div:first-of-type {
        margin-top: 100px;
    }
}