@import "../../styles/main.scss";

#projects-section {
    width: 100%;
    padding: 100px 0;
    background: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin: 0;

        color: $hex-hero-dark;
        font-family: 'Hind', sans-serif;
        font-size: 200%;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .underblock {
        background-color: $hex-hero-blue;
        width: 8%;
        height: 3px;
        margin: 40px 0;
        display: block;
    }

    .grid-wrapper {
        width: 100%;
        margin-bottom: 80px;

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .project-item {
            display: block;
            position: relative;
            
            width: 33.33333%;
            text-align: initial;

            img {
                width: 100%;
            }

            > div {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                flex-direction: column;
                justify-content: center;

                width: 100%;
                height: 100%;
                background: rgba(31, 40, 49, 0.9);

                h2 {
                    color: white;
                    font-family: 'Hind', sans-serif;
                    font-size: 200%;
                    font-weight: 400;
                    letter-spacing: 2px;
                    text-align: center;
                    text-transform: uppercase;
                }

                a {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 120%;
                    font-weight: 400;
                    text-align: center;
                    text-decoration: none;

                    &:hover {
                        color: $hex-hero-blue;
                    }
                }
            }

            &:hover > div {
                display: flex;
            }
        }

        @include breakTarget(ipad, (max-height: height, max-width: width), portrait) {
            .project-item {
                width: 50%;
            }
        }
        @include breakTarget(iphone6-8-plus, (max-height: height, max-width: width), portrait) {
            align-items: center;
            justify-content: center;

            .project-item {
                width: 80%;
                margin: 20px 0;
            }
        }
    }
}