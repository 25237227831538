@import "../../styles/main.scss";

#about-section {
    max-width: 100%;
    min-height: 110vh;
    padding: 50px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakTarget(ipad, (max-height: height, max-width: width), both) {
        min-height: 100vh;
    }

    img {
        width: 250px;
        margin: 20px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        box-shadow: 0px 5px 30px #666;
        @include border-radius(50%);

        @include breakTarget(iphone6-8-plus, (max-height: height, max-width: width), both) {
            width: 200px;
        }
    }

    h2 {
        margin: 0;

        color: $hex-hero-dark;
        font-family: 'Hind', sans-serif;
        font-size: 200%;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .underblock {
        background-color: $hex-hero-blue;
        width: 8%;
        height: 3px;
        margin: 40px 0;
        display: block;
    }
    p {
        padding: 0 15%;
        border: none;

        color: #666;
        font-family: 'Hind', sans-serif;
        font-size: 140%;
        font-weight: 100;

        @include breakTarget(iphone6-8-plus, (max-height: height, max-width: width), both) {
            padding: 0;
            font-size: 100%;
        }
    }
}
